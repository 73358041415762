import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PartnersList from './PartnersList/PartnersList'
import PartnersManager from './PartnersManager/PartnersManager'

function Routes() {
  return (
    <>
      <Route path="/partners" exact component={PartnersList} />
      <Route path="/partners/add" exact component={PartnersManager} />
      <Route path="/partners/edit/:id" exact component={PartnersManager} />
    </>
  )
}

export default Routes
