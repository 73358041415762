import React from 'react'
import { Route } from 'react-router-dom'
import FaqList from './FaqList/FaqList'
import FaqManager from './FaqManager/FaqManager'

function Routes() {
  return (
    <>
      <Route path="/faq" exact component={FaqList} />
      <Route path="/faq/add" exact component={FaqManager} />
      <Route path="/faq/edit/:id" exact component={FaqManager} />
    </>
  )
}

export default Routes
