import React from 'react'
import { Route } from 'react-router'
import Tours from '../../components/Tours/index'
import Faq from '../../components/Faq/index'
import Feeds from '../../components/Feeds/index'
import Partners from '../../components/Partners/index'
import Settings from '../../components/Settings/Settings'
import Qrcode from '../../components/Qrcode/Qrcode'
import Pages from '../../components/Pages/index'

function Routes() {
  return (
    <div className="Routes">
      <Route path="/" component={Tours} />
      <Route path="/faq" component={Faq} />
      <Route path="/pages" component={Pages} />
      <Route path="/feeds" component={Feeds} />
      <Route path="/partners" component={Partners} />
      <Route path="/settings" component={Settings} />
      <Route path="/qrcode" component={Qrcode} />
    </div>
  )
}

export default Routes
