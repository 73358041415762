import { useState, useEffect } from 'react'
import { isEmpty, isNull } from 'lodash'
import usePrevious from '../../../hooks/usePrevious'

const useModuleInit = ({ formData, initialState }) => {
  const [subform, setSubform] = useState(!isEmpty(formData) ? formData : initialState)
  const prevSubform = usePrevious(formData)

  useEffect(() => {
    if (formData && isNull(prevSubform)) setSubform(formData)
  }, [formData])

  return [subform, setSubform]
}

export default useModuleInit
