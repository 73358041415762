import React, { useRef, useEffect } from 'react'
import './Backdrop.sass'

function Backdrop({ show, close = () => null, children, className, transparentBackDrop, style }) {
  const backdropRef = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleClickOutside = (event) => {
    if (backdropRef && backdropRef.current === event.target) {
      close()
    }
  }

  const classes = ['Backdrop']
  if (transparentBackDrop) classes.push('Backdrop_theme_transparent')
  if (className) classes.push(className)

  return show ? (
    <div ref={backdropRef} className={classes.join(' ')} style={style}>
      {children}
    </div>
  ) : null
}

export default Backdrop
