import * as SIGN_IN_ERRORS from './authErrors'

const handleSignInErrors = ({ code }) => {
  switch (code) {
    case 'auth/wrong-password':
      return SIGN_IN_ERRORS.WRONG_PASSWORD
    case 'auth/user-not-found':
      return SIGN_IN_ERRORS.USER_NOT_FOUND
    case 'auth/not-a-member':
      return SIGN_IN_ERRORS.USER_NOT_A_MEMBER
    case 'auth/blocked-member':
      return SIGN_IN_ERRORS.USER_IS_BLOCKED
    default:
      return code
  }
}

export function CustomAuthException({ message, code }) {
  const error = new Error(message)
  error.code = code
  return error
}

export default handleSignInErrors
