import React, { useState } from 'react'
import './DeletePopUp.sass'
import PopUp from '../PopUp'
import Icon from '../../Icon/Icon'
import Button from '../../Button/Button'

export default function DeletePopUp({
  title,
  warningMessage,
  closePopUp,
  submitDelete,
}) {
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    submitDelete().then(() => closePopUp())
  }

  return (
    <PopUp
      title={title}
      customButtons={[
        <Button
          isLoading={isLoading}
          onClick={onDelete}
          title="Удалить"
          theme="solid"
          fill="noty"
        />,
        <Button
          onClick={closePopUp}
          title="Отменить"
          theme="bounded"
          border="gray"
          color="gray"
        />,
      ]}
      show
      close={closePopUp}
    >
      <div className="DeletePopUp-WarningMessage">
        <Icon name="exclamation-triangle" className="DeletePopUp-WarningIcon" />
        <p>{warningMessage}</p>
      </div>
    </PopUp>
  )
}
