import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyAGl7af68bZHUNepIZIx8OTg2S_52npu4s',
  authDomain: 'isee-iterra.firebaseapp.com',
  projectId: 'isee-iterra',
  storageBucket: 'isee-iterra.appspot.com',
  messagingSenderId: '215035550926',
  appId: '1:215035550926:web:c7c139649c958f29a2c49f',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
