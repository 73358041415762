import React from 'react'
import { Img } from 'react-image'
import Skeleton from '../Skeleton/Skeleton'

function Image({ src = '', className }) {
  return (
    <Img
      key={src}
      className={className}
      src={src}
      loader={<Skeleton className={className} />}
    />
  )
}
export default Image
