import getFieldValidationObject from '../validation/fieldValidationObject'
import cloneDeep from 'lodash/cloneDeep'
import * as FIELD_ERRORS from '../fieldErrors'

const checkboxPattern = {
  fieldId: '',
  config: { label: '' },
  fieldType: 'checkbox',
  validation: getFieldValidationObject(),
  value: false,
  valid: true,
  error: '',
  touched: false,
}

export const createCheckbox = ({
  fieldId,
  label,
  required,
  defaultError,
  value,
}) => {
  const field = cloneDeep(checkboxPattern)

  field.fieldId = fieldId
  field.config.label = label
  field.validation.required = required
  field.value = value || false
  field.valid = !required

  if (value) {
    field.valid = true
    field.touched = true
    field.error = ''
  }

  if (defaultError) field.error = defaultError
  else field.error = required ? FIELD_ERRORS.EMPTY_FIELD : ''

  return field
}
