import { db } from '../../config/firebase'

const getDoc = ({ path, docId, docIdName = null }) =>
  db
    .collection(path)
    .doc(docId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return {
          ...(docIdName ? { [docIdName]: doc.id } : {}),
          ...doc.data(),
        }
      }
      return null
    })

export default getDoc
