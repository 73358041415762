import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import './ToursCatalog.sass'
import { find, isEmpty, orderBy } from 'lodash'
import Img from '../../../ui/Img/Img'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onTourDelete from '../ToursManager/functions/deleteTour'
import SortableList from '../../../ui/SortableList/SortableList'
import arrayMove from 'array-move'
import { updateDoc } from '../../../utils/db/updateDoc'

function Tours({ ...router }) {
  const [tourCatalog, setTourCatalog] = useState(null)
  const [deleteTour, setDeleteTour] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    db.collection('tours').onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ tourId: d.id, ...d.data() }))
      setTourCatalog(orderBy(data, ['pos'], ['asc']))
    })
  }, [])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newTours = arrayMove(tourCatalog, oldIndex, newIndex)
    setTourCatalog(newTours)
  }

  const updateToursOrder = () => {
    setIsLoading(true)
    Promise.all(
      tourCatalog.map((t, i) =>
        updateDoc({
          path: 'tours',
          docId: t.tourId,
          data: { pos: i },
        })
      )
    )
      .then(() => setIsLoading(false))
      .catch(() => {
        console.log('error')
      })
  }

  return (
    <RouteComponent
      title="Каталог туров"
      controlls={
        <>
          <Button
            title="Обновить порядок"
            icon="save"
            fill="accent2"
            theme="solid"
            type="button"
            isLoading={isLoading}
            onClick={updateToursOrder}
            className="ToursCatalog-UpdateSortButton"
          />
          <Button
            title="Добавить тур"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/tours/add"
            size={28}
          />
        </>
      }
    >
      {deleteTour && (
        <DeletePopUp
          title="Удаление тура"
          warningMessage={`Вы уверены, что хотите удалить тур "${deleteTour.title}"?`}
          closePopUp={() => setDeleteTour(null)}
          submitDelete={() => onTourDelete({ tourId: deleteTour.tourId })}
        />
      )}
      <div className="ToursCatalog">
        {tourCatalog ? (
          isEmpty(tourCatalog) ? (
            <p className="EmptyText">Нет туров</p>
          ) : (
            <SortableList
              className="ToursCatalog-SortableGrid"
              axis="y"
              onSortEnd={onSortEnd}
              pressDelay={200}
              items={tourCatalog.map((t, i) => (
                <TourTile
                  key={`tour-${i}`}
                  {...t}
                  {...t.ru}
                  onEdit={() => router.history.push(`/tours/edit/${t.tourId}`)}
                  onDelete={() =>
                    setDeleteTour({
                      title: t.ru.title,
                      tourId: t.tourId,
                    })
                  }
                />
              ))}
            />
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function TourTile({
  title,
  isPublished,
  updated,
  photos,
  season,
  onEdit,
  onDelete,
}) {
  return (
    <div className="TourTile" onDoubleClick={onEdit}>
      <div className="Avatar">
        <Img src={find(photos, ['isAvatar', true]).publicUrl} />
      </div>
      <div className="Content">
        <p className="Title">{title}</p>
        <InfoLine title="Сезон" content={season} />
        <InfoLine
          title="Статус"
          content={
            isPublished ? (
              <span className="Status Published">Опубликован</span>
            ) : (
              <span className="Status NotPublished">Неопубликован</span>
            )
          }
        />
        <InfoLine
          title="Посл. обновление"
          content={formatDateAsDayMontYearHHMM(updated)}
        />
      </div>
      <div className="Options">
        <div className="OptionButton Gray" onClick={onEdit}>
          <Icon name="expand-arrows-alt" />
        </div>
        <div className="OptionButton Accent" onClick={onEdit}>
          <Icon name="pencil-alt" />
        </div>
        <div className="OptionButton Red" onClick={onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(Tours)
