import React, { useEffect, useState } from 'react'
import './FeedsManager.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utils/newforms/createForm'
import FormRender from '../../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../../RouteComponent/RouteComponent'
import PhotoManager from '../../PhotoManager/PhotoManager'
import syncForm from '../../../utils/newforms/changed/syncForm'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import { isEmpty, omit, pick } from 'lodash'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import getDoc from '../../../utils/db/getDoc'
import uploadPhotos from './functions/uploadPhotos'
import usePrevious from '../../../hooks/usePrevious'

function FeedsManager({ ...router }) {
  const feedId = router.match.params.id
  const [form, setForm] = useState(
    !feedId ? createForm({ formPattern: new FeedForm() }) : null
  )
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (feedId) {
      getDoc({ path: 'feeds', docId: feedId }).then((data) =>
        setForm(
          createForm({
            formPattern: new FeedForm(),
            formData: data,
          })
        )
      )
    }
  }, [feedId])

  // const prevLang = usePrevious(form)
  // useEffect(() => {
  //   if (
  //     form &&
  //     form.lang.value &&
  //     prevLang &&
  //     prevLang.lang.value &&
  //     form.lang.value !== prevLang.lang.value
  //   ) {
  //     if (tourId) {
  //       getDoc({ path: 'tours', docId: tourId }).then((data) =>
  //         setForm(
  //           createForm({
  //             formPattern: new TourForm(),
  //             formData: {
  //               ...data,
  //               ...data[form.lang.value],
  //               lang: form.lang.value,
  //             },
  //           })
  //         )
  //       )
  //     } else {
  //       setForm(
  //         createForm({
  //           formPattern: new TourForm(),
  //         })
  //       )
  //     }
  //   }
  // }, [form])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
    }

    if (isEmpty(form.photos.values)) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, добавъте аватар к проекту',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    const data = getFormValues({ form })

    const values = {
      ...data,
      updated: new Date(),
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
    }

    const opRef = feedId
      ? updateDoc({ path: 'feeds', docId: feedId, data: values })
      : addDoc({ path: 'feeds', data: values })

    opRef.then((docId) =>
      uploadPhotos({ photos: data.photos, docId })
        .then(() => {
          router.history.push(`/feeds/edit/${docId}`)
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: FeedsManager.jsx ~ line 79 ~ onSubmit ~ error',
            error
          )
        })
    )
  }

  return (
    <RouteComponent
      title={feedId ? 'Редактировать отзыв' : 'Добавить новый отзыв'}
    >
      <div className="FeedsManager-Content">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <p className="Title">Основная информация</p>
        <div className="MainInfo">
          <FormRender
            sections={[
              {
                fields: ['author', 'text', 'photoLinks'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div>
        <p className="Title">Фотогаллерея</p>
        <PhotoManager
          projectId={feedId}
          projectPath="feeds"
          formData={form ? form.photos.values : null}
          syncState={(moduleState) =>
            syncForm({
              propIdentifier: 'photos',
              form,
              setForm,
              newForm: moduleState,
            })
          }
        />
        <div className="Buttons">
          <Button
            title="Сохранить"
            theme="solid"
            fill="accent"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            title="Закрыть"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            isLoading={isLoading}
            onClick={() => router.history.push('/feeds/')}
          />
        </div>
      </div>
    </RouteComponent>
  )
}

class FeedForm {
  constructor() {
    this.author = {
      field: {
        fieldId: 'author',
        fieldType: 'input',
        inputType: 'text',
        label: 'Имя туриста',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.text = {
      field: {
        fieldId: 'text',
        fieldType: 'texteditor',
        label: 'Текст отзыва',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.photoLinks = {
      field: {
        fieldId: 'photoLinks',
        fieldType: 'texteditor',
        // inputType: 'text',
        label: 'Ссылки на фото',
        required: false,
      },
      render: getFieldRenderObject(),
    }
    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default withRouter(FeedsManager)
