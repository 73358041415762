import React from 'react'
import { Route } from 'react-router-dom'
import PagesList from './PagesList/PagesList'
import PagesManager from './PagesManager/PagesManager'

function Routes() {
  return (
    <>
      <Route path="/pages" exact component={PagesList} />
      <Route path="/pages/add" exact component={PagesManager} />
      <Route path="/pages/edit/:id" exact component={PagesManager} />
    </>
  )
}

export default Routes
