import React, { useState, useEffect } from 'react'
import './StatusMessage.sass'

function StatusMessage({ className, type, message }) {
  let theme
  switch (type) {
    case 'fail':
      theme = 'StatusMessage_theme_fail'
      break
    case 'success':
      theme = 'StatusMessage_theme_success'
      break
    default:
      break
  }

  return <div className={['StatusMessage', [theme], ...(className ? [className] : [])].join(' ')}>{message}</div>
}

export const initStatusMessage = () => ({
  show: false,
  type: '',
  message: '',
  closeAfter: null,
})

export const useStatusMessage = () => {
  const [statusMessage, setStatusMessage] = useState(initStatusMessage())

  useEffect(() => {
    if (statusMessage.show && statusMessage.closeAfter) {
      setTimeout(() => setStatusMessage(initStatusMessage()), statusMessage.closeAfter)
    }
  }, [statusMessage])

  return [statusMessage, setStatusMessage]
}

export default StatusMessage
