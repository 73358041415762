import { useState, useEffect } from 'react'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

const useFilterObjValues = (rawData) => {
  const [filterString, setFilterString] = useState('')
  const [filteredData, setFilteredData] = useState(null)

  useEffect(() => {
    if (isArray(rawData)) {
      const sorted = rawData.filter((element) => isSuitable(element))
      setFilteredData(sorted)
    } else {
      setFilteredData(null)
    }
  }, [rawData, filterString])

  const isStringIncludes = (val, string) => !!val.toLowerCase().includes(string.toLowerCase())

  const isSuitable = (element) => {
    const arrayOfValues = Object.values(element)
    let suitableObject = false
    arrayOfValues.forEach((value) => {
      if (!suitableObject) {
        if (isString(value)) suitableObject = isStringIncludes(value, filterString)
        if (isObject(value)) suitableObject = isSuitable(value)
      }
    })
    return suitableObject
  }

  return [filteredData, setFilterString]
}

export default useFilterObjValues
