import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import './PartnersList.sass'
import { find, isEmpty, orderBy } from 'lodash'
import Img from '../../../ui/Img/Img'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onPartnerDelete from '../PartnersManager/functions/deletePartner'

function PartnersList({ ...router }) {
  const [partnersCatalog, setPartnersCatalog] = useState(null)
  const [deletePartner, setDeletePartner] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    db.collection('partners').onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ partnerId: d.id, ...d.data() }))
      setPartnersCatalog(orderBy(data, ['created']))
    })
  }, [])

  return (
    <RouteComponent
      title="Партнёры"
      controlls={
        <>
          <Button
            title="Добавить партнёра"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/partners/add"
            size={28}
          />
        </>
      }
    >
      {deletePartner && (
        <DeletePopUp
          title="Удаление партнёра"
          warningMessage={`Вы уверены, что хотите удалить партнёра "${deletePartner.title}"?`}
          closePopUp={() => setDeletePartner(null)}
          submitDelete={() =>
            onPartnerDelete({ partnerId: deletePartner.partnerId })
          }
        />
      )}
      <div className="ToursCatalog">
        {partnersCatalog ? (
          isEmpty(partnersCatalog) ? (
            <p className="EmptyText">Нет партнёров</p>
          ) : (
            partnersCatalog.map((t, i) => (
              <TourTile
                key={`partner-${i}`}
                {...t}
                onEdit={() =>
                  router.history.push(`/partners/edit/${t.partnerId}`)
                }
                onDelete={() =>
                  setDeletePartner({
                    title: t.title,
                    partnerId: t.partnerId,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function TourTile({ title, updated, photos, onEdit, onDelete }) {
  return (
    <div className="TourTile" onDoubleClick={onEdit}>
      <div className="Avatar">
        <Img src={find(photos, ['isAvatar', true]).publicUrl} />
      </div>
      <div className="Content">
        <p className="Title">{title}</p>
        <InfoLine
          title="Посл. обновление"
          content={formatDateAsDayMontYearHHMM(updated)}
        />
      </div>
      <div className="Options">
        <div className="OptionButton Accent" onClick={onEdit}>
          <Icon name="pencil-alt" />
        </div>
        <div className="OptionButton Red" onClick={onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(PartnersList)
