import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import FeedsList from './FeedsList/FeedsList'
import FeedsManager from './FeedsManager/FeedsManager'

function Routes() {
  return (
    <>
      <Route exact path="/">
        <Redirect to="/tours" />
      </Route>
      <Route path="/feeds" exact component={FeedsList} />
      <Route path="/feeds/add" exact component={FeedsManager} />
      <Route path="/feeds/edit/:id" exact component={FeedsManager} />
    </>
  )
}

export default Routes
