import React from 'react'
// import SceneContentHeader from '../../../components/UI/SceneContent/SceneContentHeader/SceneContentHeader'
import FieldRender from './FieldRender'

function SectionRender({
  title,
  component = false,
  fields,
  form,
  setForm,
  errors,
  isFormSaved,
  closeForm,
}) {
  return (
    <>
      {/* {title && <SceneContentHeader title={title} />} */}
      {component ||
        fields.map((field) => (
          <FieldRender
            isFormSaved={isFormSaved}
            key={field}
            field={{ ...form[field], fieldId: field }}
            form={form}
            setForm={setForm}
            showErrors={errors}
            closeForm={closeForm}
          />
        ))}
    </>
  )
}

export default SectionRender
