import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import './FeedsList.sass'
import { find, isEmpty, orderBy } from 'lodash'
import Img from '../../../ui/Img/Img'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onFeedDelete from '../FeedsManager/functions/deleteFeed'
import SortableList from '../../../ui/SortableList/SortableList'
import arrayMove from 'array-move'
import { updateDoc } from '../../../utils/db/updateDoc'
import parse from 'html-react-parser'

function FeedsList({ ...router }) {
  const [feedCatalog, setFeedCatalog] = useState(null)
  const [deleteFeed, setDeleteFeed] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    db.collection('feeds').onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ feedId: d.id, ...d.data() }))
      setFeedCatalog(data)
    })
  }, [])

  return (
    <RouteComponent
      title="Отзывы"
      controlls={
        <>
          {/* <Button
            title="Обновить порядок"
            icon="save"
            fill="accent2"
            theme="solid"
            type="button"
            isLoading={isLoading}
            onClick={updateToursOrder}
            className="ToursCatalog-UpdateSortButton"
          /> */}
          <Button
            title="Добавить отзыв"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/feeds/add"
            size={28}
          />
        </>
      }
    >
      {deleteFeed && (
        <DeletePopUp
          title="Удаление отзыва"
          warningMessage={`Вы уверены, что хотите удалить отзыв "${deleteFeed.title}"?`}
          closePopUp={() => setDeleteFeed(null)}
          submitDelete={() => onFeedDelete({ feedId: deleteFeed.feedId })}
        />
      )}
      <div className="ToursCatalog">
        {feedCatalog ? (
          isEmpty(feedCatalog) ? (
            <p className="EmptyText">Нет отзывов</p>
          ) : (
            feedCatalog.map((t, i) => (
              <TourTile
                key={`feed-${i}`}
                {...t}
                onEdit={() => router.history.push(`/feeds/edit/${t.feedId}`)}
                onDelete={() =>
                  setDeleteFeed({
                    title: t.author,
                    feedId: t.feedId,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function TourTile({ author, text, updated, photos, onEdit, onDelete }) {
  return (
    <div className="TourTile" onDoubleClick={onEdit}>
      <div className="Avatar">
        <Img src={find(photos, ['isAvatar', true]).publicUrl} />
      </div>
      <div className="Content">
        <p className="Title">{author}</p>
        <InfoLine title="Текст" content={parse(text)} />
        <InfoLine
          title="Посл. обновление"
          content={formatDateAsDayMontYearHHMM(updated)}
        />
      </div>
      <div className="Options">
        <div className="OptionButton Accent" onClick={onEdit}>
          <Icon name="pencil-alt" />
        </div>
        <div className="OptionButton Red" onClick={onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(FeedsList)
