import { cloneDeep, isString, isArray, isObject, isBoolean, isEmpty, find } from 'lodash'

const dropFieldValue = ({ fieldIdentifier, form, setForm, fieldset }) => {
  const formClone = cloneDeep(form)
  let field = formClone[fieldIdentifier]

  if (!isEmpty(fieldset)) {
    field = find(field.values[fieldset.pos], ['fieldId', fieldset.fieldId])
  }

  if (field.render.isSystem) {
    field.values = isString(field.values)
      ? ''
      : isArray(field.values)
      ? []
      : isObject(field.values)
      ? {}
      : isBoolean(field.values)
      ? false
      : null
  } else {
    field.value = isString(field.value) ? '' : []
    field.valid = !field.validation.required
    field.error = ''
    field.touched = false

    if (field.fieldType === 'select') {
      field.config.icon = 'angle-down'
      field.config.iconClass = ''
    } else {
      field.config.icon = ''
    }
  }

  setForm(formClone)
}

export default dropFieldValue
