import React from 'react'
import './RouteComponent.sass'

function RouteComponent({ title, children, controlls }) {
  return (
    <div className="RouteComponent">
      <div className="RouteComponent-Header">
        <h5>{title}</h5>
        {controlls && <div className="Controlls">{controlls}</div>}
      </div>
      <div className="RouteComponent-Body">{children}</div>
    </div>
  )
}

export default RouteComponent
