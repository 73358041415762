import { cloneDeep, isEmpty, isString } from 'lodash'
import getFieldValidationObject from '../validation/fieldValidationObject'
import * as FIELD_ERRORS from '../fieldErrors'

const selectboxPattern = {
  fieldId: '',
  fieldType: 'select',
  config: {
    label: '',
    getOptions: [],
    useDropValueButton: false,
  },
  validation: getFieldValidationObject(),
  value: '',
  valid: true,
  error: '',
  touched: false,
}

export const createSelectbox = ({
  fieldId,
  label,
  placeholder,
  required,
  getOptions,
  multiOptions,
  emptyOptionsText,
  isSearchable,
  defaultError,
  setPropAsLabel,
  value,
  useDropValueButton = false,
  isChangable = true,
}) => {
  const field = cloneDeep(selectboxPattern)
  field.fieldId = fieldId
  field.isChangable = isChangable
  field.config.label = label
  field.config.placeholder = placeholder
  field.config.getOptions = getOptions
  field.config.isSearchable = isSearchable
  field.config.emptyOptionsText = emptyOptionsText || ''
  field.config.useDropValueButton = useDropValueButton
  field.validation.required = required
  field.valid = !required
  field.value = value || ''
  field.setPropAsLabel = setPropAsLabel
  if (defaultError) field.error = defaultError
  else field.error = required ? FIELD_ERRORS.EMPTY_FIELD : ''

  field.config.multiOptions = multiOptions
  if (multiOptions) {
    field.value = isString(value) && value ? [value] : !isEmpty(value) ? value : []
  }

  if (multiOptions) {
    if (!isEmpty(value)) {
      field.valid = true
      field.touched = true
      field.error = ''
    }
  } else if (value) {
    field.valid = true
    field.touched = true
    field.error = ''
  }

  return field
}
