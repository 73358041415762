import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import './FaqList.sass'
import { isEmpty, orderBy } from 'lodash'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onOptionDelete from '../FaqManager/functions/deleteFaq'
import getCollection from '../../../utils/db/getCollection'

function RulesList({ ...router }) {
  const [rulesArr, setRulesArr] = useState(null)
  const [deleteRule, setDeleteRule] = useState(null)

  useEffect(() => {
    db.collection('faq').onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ ruleId: d.id, ...d.data() }))
      setRulesArr(
        orderBy(
          data.filter((d) => !d.isDeleted && true),
          ['position'],
          ['asc']
        )
      )
    })
  }, [])

  return (
    <RouteComponent
      title="Часто задаваемые вопросы"
      controlls={
        <Button
          title="Добавить вопрос"
          icon="plus"
          fill="accent"
          theme="solid"
          type="navlink"
          path="/faq/add"
          size={28}
        />
      }
    >
      {deleteRule && (
        <DeletePopUp
          title="Удаление вопроса"
          warningMessage={`Вы уверены, что хотите удалить вопрос "${deleteRule.title}"?`}
          closePopUp={() => setDeleteRule(null)}
          submitDelete={() => onOptionDelete({ ruleId: deleteRule.ruleId })}
        />
      )}
      <div className="OptionsList">
        {rulesArr ? (
          isEmpty(rulesArr) ? (
            <p className="EmptyText">Нет вопросов</p>
          ) : (
            rulesArr.map((o) => (
              <RuleTile
                key={o.ruleId}
                {...o}
                onEdit={() => router.history.push(`/faq/edit/${o.ruleId}`)}
                onDelete={() =>
                  setDeleteRule({
                    title: o.title_ru,
                    ruleId: o.ruleId,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function RuleTile(data) {
  return (
    <div className="OptionTile" onDoubleClick={data.onEdit}>
      <div className="CarDate">{formatDateAsDayMontYearHHMM(data.updated)}</div>
      <div className="Content">
        <p className="Title">{data.title_ru}</p>
      </div>

      <div className="Options">
        <div className="OptionButton Accent" onClick={data.onEdit}>
          <Icon name="pencil-alt" weight="solid" />
        </div>
        <div className="OptionButton Red" onClick={data.onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(RulesList)
