import React from 'react'
import { cloneDeep, isString, isArray } from 'lodash'
import fieldChangedHandler from '../changed/fieldChangedHandler'
import Field from '../../../ui/Field/Field'
import dropFieldValue from '../changed/dropFieldValue'
import Button from '../../../ui/Button/Button'

function FieldRender({
  field,
  form,
  setForm,
  showErrors = false,
  cb = null,
  isFormSaved,
  closeForm = false,
}) {
  const isChangable = closeForm
    ? false
    : !(
        field.render.hasOwnProperty('isChangableAfterSave') &&
        !field.render.isChangableAfterSave &&
        isFormSaved
      ) !== false

  if (field.render.isSystem) return ''
  if (
    field.render.byDefault ||
    (field.render.ifFieldId &&
      isString(field.render.value) &&
      field.render.value === form[field.render.ifFieldId].value) ||
    (isArray(field.render.value) &&
      field.render.value.includes(form[field.render.ifFieldId].value))
  ) {
    if (field.isFieldset) {
      return (
        <div className="Fieldset">
          {field.values.map((s, sp) => (
            <>
              <div className="Fields">
                {s.map((v, i) => {
                  const f = cb ? cb(v) : v
                  return (
                    <Field
                      key={`${sp}-${v.fieldId}`}
                      {...f}
                      showErrors={showErrors}
                      changed={fieldChangedHandler({
                        fieldIdentifier: field.fieldId,
                        form,
                        setForm,
                        fieldset: { pos: sp, fieldId: f.fieldId },
                      })}
                      dropValue={() =>
                        dropFieldValue({
                          fieldIdentifier: field.fieldId,
                          form,
                          setForm,
                          fieldset: { pos: sp, fieldId: f.fieldId },
                        })
                      }
                      class={field.render.className}
                      isChangable={isChangable}
                    />
                  )
                })}
              </div>
              {isChangable ? (
                sp === 0 ? (
                  <Button
                    theme="circle"
                    fill="accent"
                    icon="plus"
                    className="Fieldset-Button"
                    onClick={() => addFieldsetRow({ field, form, setForm })}
                  />
                ) : (
                  <Button
                    theme="circle"
                    fill="noty"
                    icon="minus"
                    className="Fieldset-Button"
                    onClick={() =>
                      removeFieldsetRow({ field, index: sp, form, setForm })
                    }
                  />
                )
              ) : null}
            </>
          ))}
        </div>
      )
    }

    const f = cb ? cb(field) : field

    return (
      <Field
        key={`field-${f.fieldId}`}
        {...f}
        showErrors={showErrors}
        changed={fieldChangedHandler({
          fieldIdentifier: f.fieldId,
          form,
          setForm,
        })}
        dropValue={() =>
          dropFieldValue({ fieldIdentifier: f.fieldId, form, setForm })
        }
        class={field.render.className}
        isChangable={isChangable}
      />
    )
  }
  return ''
}

const addFieldsetRow = ({ field, form, setForm }) => {
  const formClone = cloneDeep(form)
  const fieldset = formClone[field.fieldId]
  fieldset.values.push(cloneDeep(fieldset.newValue))
  setForm(formClone)
}

const removeFieldsetRow = ({ field, index, form, setForm }) => {
  const formClone = cloneDeep(form)
  const fieldset = formClone[field.fieldId]
  fieldset.values.splice(index, 1)
  setForm(formClone)
}

export default FieldRender
