import React, { useEffect, useState } from 'react'
import './ToursManager.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utils/newforms/createForm'
import FormRender from '../../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../../RouteComponent/RouteComponent'
import PhotoManager from '../../PhotoManager/PhotoManager'
import syncForm from '../../../utils/newforms/changed/syncForm'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import { isEmpty, omit, pick } from 'lodash'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import getDoc from '../../../utils/db/getDoc'
import uploadPhotos from './functions/uploadPhotos'
import usePrevious from '../../../hooks/usePrevious'

function ToursManager({ ...router }) {
  const tourId = router.match.params.id
  const [form, setForm] = useState(
    !tourId ? createForm({ formPattern: new TourForm() }) : null
  )
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (tourId) {
      getDoc({ path: 'tours', docId: tourId }).then((data) =>
        setForm(
          createForm({
            formPattern: new TourForm(),
            formData: { ...data, ...data.ru },
          })
        )
      )
    }
  }, [tourId])

  const prevLang = usePrevious(form)
  useEffect(() => {
    if (
      form &&
      form.lang.value &&
      prevLang &&
      prevLang.lang.value &&
      form.lang.value !== prevLang.lang.value
    ) {
      if (tourId) {
        getDoc({ path: 'tours', docId: tourId }).then((data) =>
          setForm(
            createForm({
              formPattern: new TourForm(),
              formData: {
                ...data,
                ...data[form.lang.value],
                lang: form.lang.value,
              },
            })
          )
        )
      } else {
        setForm(
          createForm({
            formPattern: new TourForm(),
          })
        )
      }
    }
  }, [form])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
    }

    if (isEmpty(form.photos.values)) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, добавъте аватар к проекту',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    const data = getFormValues({ form })

    const values = {
      ...pick(data, ['isPublished', 'pos', 'created']),
      [data.lang]: omit(data, [
        'isPublished',
        'pos',
        'created',
        'updated',
        'photos',
      ]),
      updated: new Date(),
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
    }

    const opRef = tourId
      ? updateDoc({ path: 'tours', docId: tourId, data: values })
      : addDoc({ path: 'tours', data: values })

    opRef.then((docId) =>
      uploadPhotos({ photos: data.photos, docId })
        .then(() => {
          router.history.push(`/tours/edit/${docId}`)
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
            error
          )
        })
    )
  }

  return (
    <RouteComponent title={tourId ? 'Редактировать тур' : 'Добавить новый тур'}>
      <div className="ToursManager-Content">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <p className="Tech">
          <FormRender
            sections={[
              {
                fields: [
                  'isPublished',
                  'lang',
                  'meta_title',
                  'meta_desc',
                  'meta_keywords',
                ],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </p>
        <p className="Char">
          <FormRender
            sections={[
              {
                fields: ['title', 'season', 'duration', 'price'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </p>
        <p className="Title">Основная информация</p>
        <div className="MainInfo">
          <FormRender
            sections={[
              {
                fields: ['description', 'program'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div>
        <p className="Title">Дополнительная информация</p>
        <div className="MainInfo">
          <FormRender
            sections={[
              {
                fields: ['include', 'prepare', 'photoLinks'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div>
        <p className="Title">Фотогаллерея</p>
        <PhotoManager
          projectId={tourId}
          formData={form ? form.photos.values : null}
          syncState={(moduleState) =>
            syncForm({
              propIdentifier: 'photos',
              form,
              setForm,
              newForm: moduleState,
            })
          }
        />
        <div className="Buttons">
          <Button
            title="Сохранить"
            theme="solid"
            fill="accent"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            title="Закрыть"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            isLoading={isLoading}
            onClick={() => router.history.push('/tours/')}
          />
        </div>
      </div>
    </RouteComponent>
  )
}

class TourForm {
  constructor() {
    this.meta_title = {
      field: {
        fieldId: 'meta_title',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Meta title',
      },
      render: getFieldRenderObject(),
    }
    this.meta_desc = {
      field: {
        fieldId: 'meta_desc',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Meta description',
      },
      render: getFieldRenderObject(),
    }
    this.meta_keywords = {
      field: {
        fieldId: 'meta_keywords',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Meta keywords',
      },
      render: getFieldRenderObject(),
    }
    this.isPublished = {
      field: {
        fieldId: 'isPublished',
        fieldType: 'checkbox',
        label: 'Опубликовать',
      },
      render: getFieldRenderObject(),
    }
    this.lang = {
      field: {
        fieldId: 'lang',
        fieldType: 'select',
        label: 'Язык',
        getOptions: [
          { label: 'Русский', value: 'ru' },
          { label: 'Английский', value: 'en' },
        ],
        value: 'ru',
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название тура',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.duration = {
      field: {
        fieldId: 'duration',
        fieldType: 'input',
        inputType: 'text',
        label: 'Продолжительность',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.price = {
      field: {
        fieldId: 'price',
        fieldType: 'fieldset',
        fields: [
          {
            field: {
              fieldId: 'value',
              fieldType: 'input',
              label: 'Цена',
              required: false,
              systemField: true,
              render: getFieldRenderObject(),
            },
          },
          {
            field: {
              fieldId: 'desc',
              fieldType: 'input',
              label: 'Описание',
              required: false,
              systemField: true,
              render: getFieldRenderObject(),
            },
          },
        ],
        label: 'Стоимость',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.description = {
      field: {
        fieldId: 'description',
        fieldType: 'texteditor',
        label: 'Описание тура',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.program = {
      field: {
        fieldId: 'program',
        fieldType: 'texteditor',
        label: 'Программа тура',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.include = {
      field: {
        fieldId: 'include',
        fieldType: 'texteditor',
        label: 'В стоимость включено',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.prepare = {
      field: {
        fieldId: 'prepare',
        fieldType: 'texteditor',
        label: 'Что брать с собой',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.pos = {
      field: {
        fieldId: 'pos',
        fieldType: 'input',
        inputType: 'number',
        label: 'Позиция в cписке',
      },
      render: getFieldRenderObject(),
    }
    this.season = {
      field: {
        fieldId: 'season',
        fieldType: 'input',
        inputType: 'text',
        label: 'Сезон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.photoLinks = {
      field: {
        fieldId: 'photoLinks',
        fieldType: 'textarea-autosize',
        // inputType: 'text',
        label: 'Ссылки на фото',
        required: false,
      },
      render: getFieldRenderObject(),
    }

    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default withRouter(ToursManager)
