import React from 'react'
import './Nav.sass'
import { NavLink } from 'react-router-dom'
import Icon from '../../../ui/Icon/Icon'
import { firebase } from '../../../config/firebase'

function Nav() {
  return (
    <div className="Nav">
      <div className="NavBody">
        <NavElement title="Туры" icon="mountains" link="/tours" />
        <NavElement title="Отзывы" icon="comment" link="/feeds" />
        <NavElement title="ЧаВо" icon="question" link="/faq" />
        <NavElement title="Партн." icon="handshake-alt" link="/partners" />
        <NavElement title="Страницы" icon="file-alt" link="/pages" />
        <NavElement title="Другое" icon="cog" link="/settings" />
        <NavElement title="QR code" icon="qrcode" link="/qrcode" />
      </div>

      <NavElement
        title="Выход"
        icon="sign-out-alt"
        onClick={() => firebase.auth().signOut()}
      />
    </div>
  )
}

function NavElement({ title, icon, link, onClick }) {
  const content = (
    <>
      <div className="NavElement-Icon">
        <Icon name={icon} />
      </div>
      <p className="Title">{title}</p>
    </>
  )
  return onClick ? (
    <div className="NavElement" onClick={onClick}>
      {content}
    </div>
  ) : (
    <NavLink
      to={link}
      className="NavElement"
      activeClassName="NavElement-Active"
    >
      {content}
    </NavLink>
  )
}

export default Nav
