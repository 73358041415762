import {
  startOfToday,
  endOfToday,
  startOfDay,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  endOfMonth,
} from 'date-fns'
import React from 'react'

function DateRangeOptionsPicker({ pushDate }) {
  const nextTimeOptions = [
    { label: 'За сегодня', value: 'today' },
    { label: 'За 3 дня', value: '3days' },
    { label: 'За неделю', value: 'week' },
    { label: 'За месяц', value: 'month' },
    { label: 'За квартал', value: 'quater' },
    { label: 'За год', value: 'year' },
  ]

  const getDate = ({ value }) => {
    const now = new Date()
    switch (value) {
      case 'today':
        return {
          from: startOfToday(),
          to: endOfToday(),
        }
      case '3days':
        return {
          from: startOfDay(addDays(now, -2)),
          to: endOfToday(),
        }
      case 'week':
        return {
          from: startOfWeek(now, { weekStartsOn: 1 }),
          to: endOfWeek(now, { weekStartsOn: 1 }),
        }
      case 'month':
        return {
          from: startOfMonth(now),
          to: endOfMonth(now),
        }
      case 'quater':
        return {
          from: startOfQuarter(now),
          to: endOfQuarter(now),
        }
      case 'year':
        return {
          from: startOfYear(now),
          to: endOfYear(now),
        }

      default:
        return now
    }
  }

  return (
    <div className="NextTimePicker">
      <p className="NextTimePicker-Header">Быстрые опции</p>
      <ul className="NextTimePicker-Container">
        {nextTimeOptions.map((o) => (
          <li key={o.value} className="NextTimePicker-Element" onClick={() => pushDate(getDate(o))}>
            {o.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DateRangeOptionsPicker
