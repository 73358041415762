import React from 'react'
import './InfoLine.sass'
import Icon from '../Icon/Icon'

function InfoLine({ icon, title, content, className }) {
  return (
    <p className={['InfoLine', ...(className ? [className] : [])].join(' ')}>
      {icon && <Icon name={icon} />}
      <span className="Bolder">{title}:</span>
      {content}
    </p>
  )
}

export default InfoLine
