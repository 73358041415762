import React, { useEffect, createContext, useState, useContext } from 'react'
import { Route } from 'react-router-dom'
import { firebase } from './config/firebase'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import Auth from './pages/Auth/Auth'
import Home from './pages/Home/Home'

export const AuthContext = createContext({ user: null })

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const userStateListener = firebase.auth().onAuthStateChanged(setUser)
    return () => {
      userStateListener()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  )
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <Route path="/" component={Auth} />
    </AuthProvider>
  )
}

export default App
