import React from 'react'
import './PopUp.sass'

import Backdrop from '../Backdrop/Backdrop'
import SubmitFormButtons from '../SubmitFormButtons/SubmitFormButtons'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'

function PopUp(props) {
  let buttons = null
  if (props.buttons) {
    buttons = props.buttons.map((button) => (
      <Button key={button.label} {...button}>
        {button.label}
      </Button>
    ))
  } else {
    buttons = null
  }

  return (
    <Backdrop
      transparentBackDrop={props.transparentBackDrop}
      show={props.show}
      close={props.close}
    >
      <div
        className={[
          'PopUp',
          ...(props.className ? [props.className] : []),
        ].join(' ')}
      >
        <div className="PopUp-Header">
          <span>{props.title}</span>
          <Icon onClick={props.close} name="times" />
        </div>
        <div className="PopUp-Content">{props.children}</div>
        {props.submitButtons && (
          <SubmitFormButtons
            isLoading={props.isLoading}
            onSubmit={props.onSubmit}
            onCancel={props.close}
          />
        )}
        {/* {buttons && <ControllButtons>{buttons}</ControllButtons>} */}
        {props.customButtons && (
          <div className="SubmitFormButtons">{props.customButtons}</div>
        )}
      </div>
    </Backdrop>
  )
}

export default PopUp
