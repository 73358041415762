import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import ToursCatalog from './ToursCatalog/ToursCatalog'
import ToursManager from './ToursManager/ToursManager'

function Routes() {
  return (
    <>
      <Route exact path="/">
        <Redirect to="/tours" />
      </Route>
      <Route path="/tours" exact component={ToursCatalog} />
      <Route path="/tours/add" exact component={ToursManager} />
      <Route path="/tours/edit/:id" exact component={ToursManager} />
    </>
  )
}

export default Routes
