import React from 'react'
import './SubmitFormButtons.sass'
import SaveButton from '../Button/templates/SaveButton'
import CancelButton from '../Button/templates/CancelButton'

function SubmitFormButtons({
  isLoading,
  className,
  justify = 'start',
  onSubmit,
  onCancel,
}) {
  return (
    <div
      className={[
        `SubmitFormButtons SubmitFormButtons_justify_${justify}`,
        ...(className ? [className] : []),
      ].join(' ')}
    >
      <SaveButton isLoading={isLoading} onClick={onSubmit} />
      <CancelButton onClick={onCancel} />
    </div>
  )
}

export default SubmitFormButtons
