import { cloneDeep } from 'lodash'

export const setAvatar = ({ photoIndex, photos, setPhotos }) => {
  let photosClone = cloneDeep(photos)
  const photo = photosClone[photoIndex]

  if (photo.isAvatar) {
    photo.isAvatar = false
    photosClone[0].isAvatar = true
  } else {
    photosClone = photosClone.map((p, i) => {
      if (i === photoIndex) p.isAvatar = true
      else p.isAvatar = false
      return p
    })
  }

  setPhotos(photosClone)
}
