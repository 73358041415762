import { isString } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import * as FIELD_ERRORS from '../fieldErrors'

const fieldPattern = {
  fieldId: '',
  fieldType: '',
  config: {
    type: '',
    label: '',
    icon: '',
    mask: '',
    useDropValueButton: false,
  },
  validation: {
    required: false,
    pattern: '',
  },
  value: '',
  valid: true,
  error: '',
  touched: false,
}

export const createField = ({
  fieldId,
  fieldType,
  inputType = '',
  step,
  min,
  max,
  required = false,
  label,
  placeholder,
  icon,
  iconPosition,
  iconClass,
  iconFunc,
  mask,
  validationPattern,
  defaultError,
  value,
  useDropValueButton = false,
}) => {
  const field = cloneDeep(fieldPattern)

  field.fieldId = fieldId
  field.fieldType = fieldType
  field.config.type = inputType
  field.config.label = label
  field.config.placeholder = placeholder
  field.config.useDropValueButton = useDropValueButton
  field.config.step = step
  field.config.min = min
  field.config.max = max
  field.value = value || ''
  field.initialValue = value || ''

  if (fieldType === 'inputFile') {
    field.config.inputLabel = ''
    field.value = {
      fileName: null,
      fileExt: null,
      publicUrl: null,
      storagePath: null,
      needUpload: false,
      created: new Date(),
      ...(value || {}),
    }
  }

  if (fieldType === 'phone') {
    field.country = ''
    field.initCountry = (country) => {
      field.country = country
    }
    field.value =
      (value && isString(value)) || !value
        ? {
            metadata: {
              country: '',
              formatInternational: '',
              formatNational: '',
              number: '',
            },
            value: value || '',
          }
        : value
  }

  if (fieldType === 'dateRange') {
    field.value = !value
      ? {
          from: null,
          to: null,
        }
      : value
  }

  if (mask) field.config.mask = mask

  if (icon) {
    field.config.icon = icon
    field.config.iconPosition = iconPosition
    field.config.iconClass = iconClass
    field.config.iconFunc = iconFunc
  }

  if (required) {
    field.validation.required = true
    if (validationPattern) field.validation.pattern = validationPattern
    field.valid = false
    if (defaultError) field.error = defaultError
    else field.error = FIELD_ERRORS.EMPTY_FIELD

    if (value) {
      field.valid = true
      field.touched = true
      field.error = ''
    }
  }

  return field
}
