import React, { useEffect, useState } from 'react'
import './FaqManager.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import Button from '../../../ui/Button/Button'
import FormRender from '../../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../../RouteComponent/RouteComponent'
import { createForm } from '../../../utils/newforms/createForm'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import { isNull } from 'lodash'
import getFormValues from '../../../utils/newforms/getFormValues'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import getDoc from '../../../utils/db/getDoc'
import Spinner from '../../../ui/Spinner/Spinner'

function FaqManager({ ...router }) {
  const ruleId = router.match.params.id
  const [form, setForm] = useState(
    !ruleId ? createForm({ formPattern: new RuleForm() }) : null
  )

  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (ruleId) {
      getDoc({ path: 'faq', docId: ruleId }).then((data) =>
        setForm(
          createForm({
            formPattern: new RuleForm(),
            formData: data,
          })
        )
      )
    }
  }, [ruleId])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)
    const data = getFormValues({ form })

    const opRef = ruleId
      ? updateDoc({ path: 'faq', docId: ruleId, data })
      : addDoc({ path: 'faq', data })

    opRef
      .then((docId) => {
        router.history.push(`/faq/edit/${docId}`)
        setIsLoading(false)
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Данные сохранены',
          closeAfter: 5000,
        })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch((error) => {
        console.log(
          '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <RouteComponent
      title={ruleId ? 'Редактировать вопрос' : 'Добавить новый вопрос'}
    >
      {!isNull(form) ? (
        <div className="OptionsManager-Content">
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}
          <p className="Tech">
            <FormRender
              sections={[
                {
                  fields: ['position'],
                },
              ]}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
          </p>
          <p className="Title">Информация</p>
          <div className="MainInfo">
            <FormRender
              sections={[
                {
                  fields: [
                    'title_ru',
                    'title_en',
                    'description_ru',
                    'description_en',
                  ],
                },
              ]}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
          </div>

          <div className="Buttons">
            <Button
              title="Сохранить"
              theme="solid"
              fill="accent"
              iconPosition="right"
              size={48}
              isLoading={isLoading}
              onClick={onSubmit}
            />
            <Button
              title="Закрыть"
              theme="bounded"
              color="gray"
              border="gray"
              size={48}
              isLoading={isLoading}
              onClick={() => router.history.push('/faq/')}
            />
          </div>
        </div>
      ) : (
        <Spinner type="module" />
      )}
    </RouteComponent>
  )
}

class RuleForm {
  constructor() {
    this.position = {
      field: {
        fieldId: 'position',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Позиция в списке',
      },
      render: getFieldRenderObject(),
    }
    this.title_ru = {
      field: {
        fieldId: 'title_ru',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название (RU)',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.description_ru = {
      field: {
        fieldId: 'description_ru',
        fieldType: 'texteditor',
        inputType: 'text',
        label: 'Описание (RU)',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.title_en = {
      field: {
        fieldId: 'title_en',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название (EN)',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.description_en = {
      field: {
        fieldId: 'description_en',
        fieldType: 'texteditor',
        inputType: 'text',
        label: 'Описание (EN)',
        required: true,
      },
      render: getFieldRenderObject(),
    }

    this.created = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default withRouter(FaqManager)
