import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import stylePropType from 'react-style-proptype'
import { isEmpty } from 'lodash'

import './DropdownBox.sass'
import { createForm } from '../../utils/newforms/createForm'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../utils/newforms/render/FormRender'

const DropdownBox = React.forwardRef((props, ref) => {
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        search: {
          field: {
            fieldId: 'search',
            fieldType: 'input',
            inputType: 'text',
            label: 'Поиск',
            required: false,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )

  useEffect(() => {
    if (props.isSearchable) {
      props.pushSearchVal(form.search.value)
    }
  }, [form])

  const classes = ['DropdownBox-Container']

  if (props.class) classes.push(props.class)
  if (props.className) classes.push(props.className)

  return (
    <div ref={ref} className={classes.join(' ')} style={props.styles}>
      <div className="DropdownBox">
        {props.isSearchable && (
          <FormRender
            sections={[{ fields: ['search'] }]}
            form={form}
            setForm={setForm}
          />
        )}
        {!isEmpty(props.children) ? (
          props.children
        ) : (
          <p className="DropdownBox-EmptyText">{props.emptyText}</p>
        )}
      </div>
    </div>
  )
})

DropdownBox.propTypes = {
  children: PropTypes.node.isRequired,
  isSearchable: PropTypes.bool,
  pushSearchVal: PropTypes.oneOfType([PropTypes.func, () => null]),
  class: PropTypes.string,
  styles: PropTypes.oneOfType([stylePropType, () => null]),
}

DropdownBox.defaultProps = {
  isSearchable: false,
  pushSearchVal: null,
  class: '',
  styles: null,
}

export default DropdownBox
